import React, { FunctionComponent } from 'react';
import { HomePageImageFieldsFragment } from '../../../../__generated__/graphql-client-types';
import { PLACEHOLDER_OPTIONS } from '../../../../constants/cloudinary/general';
import { CloudinaryOptions } from '../../../../helpers/cloudinary-helper/cloudinary-helper';
import { HomePageCarouselItem } from '../../../../helpers/home/home-carousel/home-carousel.helpers';
import { Link } from '../../../common-components/link/link.component';
import { CloudinaryImage } from '../../../images/cloudinary-image/cloudinary-image.component';
import { findCloudinaryOptions, isGenericCarouselItem, isSaleCategoriesItem } from '../../home-common.helpers';

export type HomeCarouselOptionCardProps<T = HomePageCarouselItem> = {
	item: T;
	imageClassName?: string;
	isSingleDisplay?: boolean;
	cloudinaryOptions?: CloudinaryOptions;
};

export const GenericCarouselCardOption: FunctionComponent<{ title: string }> = ({ title }) => (
	<span
		className="absolute z-1 bottom-0 left-0 right-0 db tc bg-theme-primary-lighter pa2 ttu fw5 f6 br2 br--bottom"
		style={{ letterSpacing: 1 }}>
		{title}
	</span>
);

export type CarouselCardOptionWithSubtitleProps = {
	title: string;
	subtitle?: string | null;
	containerClassName?: string;
	titleClassName?: string;
	subtitleClassName?: string;
};
export const CarouselCardOptionWithSubtitle: FunctionComponent<CarouselCardOptionWithSubtitleProps> = ({
	title,
	subtitle,
	containerClassName = 'flex flex-column items-center justify-between w-auto pa2 ttu fw5 f6 tracked',
	titleClassName = 'tc',
	subtitleClassName = ''
}) => (
	<span className={`${containerClassName}`}>
		<span className={`${titleClassName}`}>{title}</span>
		{subtitle && <span className={`${subtitleClassName}`}>{subtitle}</span>}
	</span>
);

export const HomeCarouselOptionFactory: FunctionComponent<{ item: HomeCarouselOptionCardProps['item'] }> = ({ item }) =>
	isSaleCategoriesItem(item) ? (
		<CarouselCardOptionWithSubtitle
			title={item.title}
			subtitle={item.subtitle}
			containerClassName="flex flex-column items-center justify-between w-auto pa2 ttu fw5 f6 tracked lh-copy"
		/>
	) : isGenericCarouselItem(item) ? (
		<GenericCarouselCardOption title={item.title} />
	) : (
		<></>
	);

export type ExclusiveDealsDisplayImageProps = {
	isSingleMobile: boolean;
	responsiveClassName?: string;
	image: HomePageImageFieldsFragment;
	cloudinaryOptions?: CloudinaryOptions;
} & Pick<HomeCarouselOptionCardProps, 'item' | 'imageClassName'>;

const ExclusiveDealsDisplayImage: FunctionComponent<ExclusiveDealsDisplayImageProps> = ({
	isSingleMobile,
	responsiveClassName,
	image,
	item,
	cloudinaryOptions,
	imageClassName
}) => (
	<CloudinaryImage
		{...image}
		className={`${imageClassName} ${responsiveClassName} w-100`}
		options={cloudinaryOptions ?? findCloudinaryOptions(item, isSingleMobile)}
		useDimensions={false}
		publicID={image.id}
		placeholderOptions={{
			...findCloudinaryOptions(item, isSingleMobile),
			...PLACEHOLDER_OPTIONS
		}}
		lowQualityPlaceholder={image.lowQualityPlaceholder}
	/>
);

export const HomeCarouselOptionCard: FunctionComponent<HomeCarouselOptionCardProps> = ({
	item,
	imageClassName = 'br2',
	// Prop used to make adjustments if there is only one exclusive deals slide
	isSingleDisplay,
	cloudinaryOptions
}) => {
	const { trackingLink, image } = item;
	const { url, tracking } = trackingLink;

	return (
		<Link className="flex flex-column items-center w-100 relative" url={url} analyticsHook={tracking} color="black">
			{isSingleDisplay ? (
				<>
					<ExclusiveDealsDisplayImage
						item={item}
						image={image}
						imageClassName={imageClassName}
						responsiveClassName="db dn-ns"
						isSingleMobile={true}
						cloudinaryOptions={cloudinaryOptions}
					/>
					<ExclusiveDealsDisplayImage
						item={item}
						image={image}
						imageClassName={imageClassName}
						responsiveClassName="dn db-ns"
						isSingleMobile={false}
						cloudinaryOptions={cloudinaryOptions}
					/>
				</>
			) : (
				<ExclusiveDealsDisplayImage
					item={item}
					image={image}
					imageClassName={imageClassName}
					isSingleMobile={false}
					cloudinaryOptions={cloudinaryOptions}
				/>
			)}

			<HomeCarouselOptionFactory item={item} />
		</Link>
	);
};
